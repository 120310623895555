<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <v-form v-model="valid">
        <v-alert
            v-model="NOTIFICATION_STATUS"
            color="green"
            elevation="24"
            type="success"
            dismissible
        >{{ NOTIFICATION }}
        </v-alert>
        <div
            v-if="err"
            style="
              margin-bottom:20px;
              margin-right:auto;
              margin-left:auto;
              display:table;
              height:50px;
              width:95%;
              padding:3%;
              background-color:#ff4949!important;
              border-radius:4px;
              color:white;
              ">
          <p v-for="(error, index) in err" :key="index">
            {{ error[0] }}
          </p>
        </div>
        <v-container>
          <v-row>
            <v-col sm="4">
              <v-text-field
                  v-model="user.first_name"
                  :rules="firstNameRules"
                  :counter="255"
                  label="First name"
                  required>
              </v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                  v-model="user.last_name"
                  :rules="lastNameRules"
                  :counter="255"
                  label="last name"
                  required>
              </v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                  v-model="user.phone"
                  :rules="phoneRules"
                  label="Phone"
                  required>
              </v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                  v-model="user.email"
                  :rules="emailRules"
                  type="email"
                  label="Email"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-btn style="box-shadow: none; color: white" height="60px" rounded @click="updateProfile" color="#39bf88"
                 v-if="valid">Update Profile
          </v-btn>
          <v-btn style="box-shadow: none; color: white" height="60px" rounded @click="updateProfile" color="#39bf88" v-else
                 disabled>Update Profile
          </v-btn>
        </v-container>
      </v-form>
    </div>
    <div v-else class="mt-8">
      <v-form v-model="valid">
        <v-alert
            v-model="NOTIFICATION_STATUS"
            color="green"
            elevation="24"
            type="success"
            dismissible
        >{{ NOTIFICATION }}
        </v-alert>
        <div
            v-if="err"
            style="
              margin-bottom:20px;
              margin-right:auto;
              margin-left:auto;
              display:table;
              height:50px;
              width:95%;
              padding:3%;
              background-color:#ff4949!important;
              border-radius:4px;
              color:white;
              ">
          <p v-for="(error, index) in err" :key="index">
            {{ error[0] }}
          </p>
        </div>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  style="border-radius: 12px" outlined
                  v-model="user.first_name"
                  :rules="firstNameRules"
                  :counter="255"
                  label="First name"
                  required>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  style="border-radius: 12px" outlined
                  v-model="user.last_name"
                  :rules="lastNameRules"
                  :counter="255"
                  label="last name"
                  required>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  style="border-radius: 12px" outlined
                  v-model="user.phone"
                  :rules="phoneRules"
                  label="Phone"
                  required>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  style="border-radius: 12px" outlined
                  v-model="user.email"
                  :rules="emailRules"
                  type="email"
                  label="Email"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-btn style="box-shadow: none; color: white;border-radius: 12px" height="45px" width="150px"  @click="updateProfile" color="#39bf88"
                 v-if="valid">Update Profile
          </v-btn>
          <v-btn style="box-shadow: none; color: white;border-radius: 12px" height="45px" width="150px" @click="updateProfile" color="#39bf88" v-else
                 disabled>Update Profile
          </v-btn>
        </v-container>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      screenType: null,
      user: [],
      valid: true,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      firstNameRules: [
        v => !!v || 'First Name is required',
      ],
      lastNameRules: [
        v => !!v || 'Last Name is required',
      ],
      phoneRules: [
        v => !!v || 'Phone Number is required',
      ],
      emailRules: [
        v => !!v || 'Email is required',
      ],
      err: null,
    }
  },
  mounted() {
    this.getScreenType()
    window.axios.get('api/me').then((data) => {
      this.user = data.data.data
    })
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    updateProfile() {
      window.axios.post('api/me/update', {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        email: this.user.email,
      }).then(() => {
        this.NOTIFICATION = 'Information Successfully Updated!';
        this.NOTIFICATION_STATUS = true;
      }).catch((err) => {
        this.err = err.response.data.errors
      })
    }
  }
}
</script>

<style scoped>

</style>